import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

THREE.ColorManagement.enabled = false

/**
 * Base
 */
// // Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
// scene.background = new THREE.Color(0x0b3a20)

// // Axes helper
// const axesHelper = new THREE.AxesHelper()
// scene.add(axesHelper)

/**
* Cursor
*/
const cursor = {
	x:0,
	y:0
} 

window.addEventListener('mousemove', (event) =>
{
	cursor.x = event.clientX / sizes.width - 0.5
	cursor.y = - (event.clientY / sizes.height -0.5)
	// console.log(cursor.x)
})



/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcaps/11.png')


/**
 * Fonts
 */
const fontLoader = new FontLoader()
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => 
    { 
        const textGeometry = new TextGeometry(
            'Make\nXR\nhappen',
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 5,
                bevelEnabled: true,
                bevelThickness: 0.03, 
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }
        )
        
        textGeometry.center()

        const material = new THREE.MeshMatcapMaterial()
        material.matcap = matcapTexture
        const text = new THREE.Mesh(textGeometry, material)
        scene.add(text)

        const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45)

        const capsuleGeometry = new THREE.CapsuleGeometry(0.3, 0.5, 10, 10)
        const capsuleMaterial = new THREE.MeshPhongMaterial({
            wireframe: true,
            color: 0xfddcd8
        })
        

        for(let i = 0; i < 100; i++)
        {
            const donut = new THREE.Mesh(donutGeometry, material)
            scene.add(donut)

            donut.position.x = (Math.random() - 0.5) * 10
            donut.position.y = (Math.random() - 0.5) * 10
            donut.position.z = (Math.random() - 0.5) * 10

            donut.rotation.x = Math.random() * Math.PI
            donut.rotation.y = Math.random() * Math.PI

            const scale = Math.random() - 0.3
            donut.scale.set(scale, scale, scale)
        }

        for(let j = 0; j < 100; j++)
        {
            const capsule = new THREE.Mesh(capsuleGeometry, capsuleMaterial)
            scene.add(capsule)

            capsule.position.x = (Math.random() - 0.5) * 10
            capsule.position.y = (Math.random() - 0.5) * 10
            capsule.position.z = (Math.random() - 0.5) * 10

            capsule.rotation.x = Math.random() * Math.PI
            capsule.rotation.y = Math.random() * Math.PI

            const capsuleScale = Math.random() - 0.5
            capsule.scale.set(capsuleScale, capsuleScale, capsuleScale)
        }
    }
)

/**
* Lights
*/
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
scene.add(ambientLight)
const pointLight = new THREE.PointLight(0xffffff, 0.5)
pointLight.position.set(1,2,3)
scene.add(pointLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 0.5
camera.position.z = 3
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))




/**
 * Animate
 */
const clock = new THREE.Clock()
const screenCenter = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2
  };


const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Ainimate donuts & capsules
    scene.traverse(mesh => {

        if(mesh.geometry instanceof THREE.TorusGeometry) {
            mesh.rotation.x += 0.01;
            mesh.rotation.y += 0.01
        }
        
        if(mesh.geometry instanceof THREE.CapsuleGeometry) {
            mesh.rotation.x += 0.01;
            mesh.rotation.y += 0.01
        }
        // Ainimate the camera
        camera.position.x = cursor.x * 10 + Math.sin(elapsedTime)
        camera.position.y = cursor.y * 10 + Math.cos(elapsedTime)
        camera.lookAt(mesh.position)
      
    });


    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()